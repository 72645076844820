@keyframes wiggle {
  0%,
  100% {
    transform: rotate(0deg);
  }
  10%,
  90% {
    transform: rotate(-5deg);
  }
  20%,
  80% {
    transform: rotate(5deg);
  }
  30%,
  50%,
  70% {
    transform: rotate(-5deg);
  }
  40%,
  60% {
    transform: rotate(5deg);
  }
}

.wiggle:hover {
  display: inline-block;
  animation: wiggle 1s infinite;
}
